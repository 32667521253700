// console.log('got to home javascript');

console.log(`%c4\u2588\u2588\u2588\u2588\u2588\u2588\u2588\`  \u2559\u2588\u2588\u2588\u2588\u2588\u2588\u2588   \u2584\u2584\u2584\u2584\u2584, \u2584\u2584    \u2584\u2584   \u2584\u2584 \u2584\u2553  \u2584\u2584, \u2584, , \u2584\u2584\u2584   \u2584\u2584\u2584\u2584, \u2553\u2584\u2584\u2584, \r\n\u256B\u2588\u2588\u2588\u2588\u2588\u2588       \u2559\u2580\u2588\u2588\u2588  \u255F\u2588\u2588\u2580\u2580\u2580\u00AC\u2553\u2588\u2588\u2588\u258C  \u2588\u2588\u2588\u2584 \u2588\u2588 \u2588\u2588\u00B5\u2590\u2588\u2588\u2588 \u2588\u2588\\\u2588\u2588\u2580\u2580\u2580\u2588\u2588 \u2588\u2588\u2580\u2580\u2588\u2588\u258C\u2588\u2588\u2588\u2580\u2588\u2588\u2588\r\n\u256B\u2588\u2588\u2588\u2588\u2580  4\u2588\u2588\u2584, \u2584\u2588\u2588\u2588\u2588  \u255F\u2588\u2588\u2588\u2588\u2588 \u2588\u2588\u2584\u2588\u2588\u258C \u2588\u2588\u2580\u2588\u2588\u2588\u2588 \u2559\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2588\u258C\u255F\u2588\u2588   \u2588\u2588\u258C\u2588\u2588\u2588\u2588\u2588\u2588\u2580\u2588\u2588\u258C  \u2588\u2588\u2310\r\n\u256B\u2588\u2588\u2588\u2580      \u2559\u2580\u2588\u2588\u2588\u2588\u2588\u2588  \u255F\u2588\u258C   \u2588\u2588\u2580\u2580\u2580\u2588\u2588\u2584\u2588\u2588\u2310 \u2588\u2588\u2588  \u255F\u2588\u2588\u2500 \u2588\u2588\u2588  \u2580\u2588\u2588\u2588\u2588\u2588\u2580 \u2588\u2588\u258C\u2559\u2588\u2588\u2584\u2588\u2588\u2588\u2588\u2588\u2588\u2580\r\n\u256B\u2588\u2588    \u2580\u2580\u2550  \u2580\u2580\u2580\u2580\u2580\u2580\u2580\r\n\u256B\u2588\r\n\u2559\r\n`,
`font-family: monospace;background-color:#1a2732;color:#FFD81B;`)

$(() => {

  const q = $("#q");
  const searchToggle = $(".search-toggle");
  const form = $("#header-search-form");

  searchToggle.on('click', function(event){
    event.preventDefault();
    if (q.hasClass('d-sm-none') || q.hasClass('d-lg-none')) {
      q.removeClass('d-sm-none').removeClass('d-lg-none');
      q.focus();
    } else {
      q.addClass('d-sm-none').addClass('d-lg-none');
    }
  });

  q.on('input', event => {
    // console.log('Got a q', event.target.value)

    if (event.code === 13 && event.target.value.length > 0) {
      $(form).trigger('submit');
    }

    // if () {
    //   searchToggle.removeAttr('disabled');
    // } else {
    //   searchToggle.attr('disabled', true);
    // }

    // This would be used for async searching
    // $.ajax({
    //   type: "GET",
    //   url: `/search/${event.target.value}.json`,
    //   success: function(searchResults){
    //     // alert('got a success')
    //     console.log('search success', searchResults);
    //   }
    // });
  })
});
